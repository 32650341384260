<template>
  <main>
    <h1>コンシェルジュ一覧</h1>
    <div class="concierge_genre_container">
      <div
        class="concierge_genre"
        :class="[count === 0 ? 'genre_selected' : '']"
        @click="setSkincare"
      >
        スキンケア
      </div>
      <div
        class="concierge_genre"
        :class="[count === 1 ? 'genre_selected' : '']"
        @click="setMakeup"
      >
        メイク
      </div>
      <div
        class="concierge_genre"
        :class="[count === 2 ? 'genre_selected' : '']"
        @click="setSkinTypeDiag"
      >
        肌質
      </div>
    </div>
    <!-- スキンケア -->
    <section
      v-show="count===0"
      class="concierge_skincare_container"
    >
      <div class="concierge_select_container">
        <div
          v-for="concierge in skincareConcierges"
          :key="concierge.id"
          class="concierge_select_btn"
          @click="selectConcierge(concierge.name_lower)"
        >
          <img
            class="concierge_select_btn_img"
            :class="[currentConciergeNumber === concierge.id ? 'selected_concierge_icon' : '']"
            :src="
              ROOT_IMAGE_URL
                + '/face-icon/'
                + concierge.name_lower
                + '.png'
            "
          >
          <p
            :class="[currentConciergeNumber === concierge.id ? 'selected_concierge_name' : '']"
          >
            {{ concierge.name }}
          </p>
        </div>
      </div>
    </section>
    <!-- スキンケア -->
    <section
      v-if="count===0"
      class="selected_concierge_container"
    >
      <section
        v-if="currentConciergeNumber===2"
        class="selected_concierge_maya"
      >
        <div class="selected_concierge_infoSnippet_container">
          <div class="selected_concierge_infoSnippet">
            <h2>Maya</h2>
            <img
              :src="
                ROOT_IMAGE_URL
                  + '/face-icon/maya.png'
              "
            >
          </div>
        </div>
        <div class="selected_concierge_info_container">
          <div class="selected_concierge_info">
            <h3>プロフィール</h3>
            <ul class="selected_concierge_info_bullets">
              <li>ミスインターナショナル、ミスユニバース、ミススプラナショナル、ミス日本etc...など日本大会出場</li>
              <li>ワンホンアジアスーパーチャレンジ&nbsp;世界大会5位</li>
              <li>ミススーパータレント&nbsp;インザワールド&nbsp;世界大会出場</li>
              <li>ミスアジア日本代表&nbsp;世界大会出場…etc</li>
              <li>ミスコン出場の傍らエステティシャン、美容部員として日本・韓国で働く</li>
              <li>モデル、キャンペーンガール、ラジオなど多数</li>
            </ul>
            <h3>コメント</h3>
            <p>こんにちは！</p>
            <p>Mayaです。</p>
            <p>私は幼稚園の頃から化粧品に興味を持つようになり、高校生の頃には成分まで調べるようになりました。</p>
            <p>
              顔の右側は商品A、左側は商品Bというように、自分で使ってみて効果を検証するのが趣味で、
              業務用の肌チェックをする機械まで家にあったりします（もはやマニア・・・笑）
            </p>
            <p>おそらく、ドラッグストアやデパコスの商品はだいたい制覇しました笑</p>
            <p>
              世の中の商品をみているとCMとかで人気芸能人を起用して人気のコスメでも、
              「使ったその瞬間はいいけど・・・」というような、長い目でみたら肌への負担が大きい商品も結構あります。
            </p>
            <p>使った瞬間はよく見えるので、成分の知識を持っていないとこういう商品の良し悪しは判断ができません。</p>
            <p>
              私のマニアぶりを知る友達からオススメのコスメを聞かれることが多かったので、
              少しでも私の知識や趣味が皆さんの役に立つならば、、、と思って作ったのがこのサービスです。
            </p>
            <p>私の美容のコンセプトは</p>
            <p>「全てのメイクも美しい肌があってこそ。健康的な肌を保って、ずっと長くナチュラルに美しく」です。</p>
            <p>どの記事・レビューもきちんとした知識に基づいて、広告費などをもらうことなく本音を書いています。</p>
            <p>皆さんのコスメ選びや、健康的な肌を保つのに少しでもお役に立てればとっても嬉しいです！</p>
          </div>
        </div>
      </section>
      <section
        v-if="currentConciergeNumber===3"
        class="selected_concierge_manami"
      >
        <div class="selected_concierge_infoSnippet_container">
          <div class="selected_concierge_infoSnippet">
            <span>トータルビジュアルプロデューサー</span>
            <h2>MANAMI</h2>
            <img
              :src="
                ROOT_IMAGE_URL
                  + '/face-icon/manami.png'
              "
            >
            <p>
              海外でのモデル経験を活かし、日本に帰国後はヘアメイク、ファッション、歩き方など「見た目に関するすべてのプロデュース」を手掛ける。<br>
              なりたいもの目指すものと本人が持つ素質を絶妙に見極めブランディングすることを得意とする。<br>
              顧客は芸能人、政治家、専門家、ミスコンテストの出場者など。
            </p>
          </div>
        </div>
        <div class="selected_concierge_info_container">
          <div class="selected_concierge_info">
            <h3>プロフィール</h3>
            <p>主な著書は「メイクの超基本テクニック（マイナビ）」「一重奥二重さんの大人メイク（タツミムック）」など全６冊。</p>
            <p>
              40歳を目前に美容業界や芸能界の人々が法律知識を持つ必要性を感じ、プレーヤー経験者の弁護士を目指して大学受験し合格。
              中央大学法学部法律学科４年に在籍中。
            </p>
            <h3>コメント</h3>
            <p>はじめましての方も、よく知って下さる方もご覧いただきありがとうございます。</p>
            <p>
              私はプロとして「使ってみて納得した」「自腹でも買いたい」「モデルさんや大切な人たちに使ってもらいたい」
              製品だけを選んでメイクボックスに入れています。
            </p>
            <p>
              私自身は超がつく乾燥肌、そして４０歳を過ぎた年齢肌、少しの敏感肌ととても面倒な肌質の持ち主ですが、
              だからこそお肌悩みがある方々のための化粧品選びはとても慎重に行っているつもりです！
            </p>
            <p>
              毎日が仕事と大学と勉強で忙しい日々を送っており、とっても根が面倒くさがりなので時短コスメやオールインワンコスメが
              大好きですので、ズボラできるコスメがお好きな方は是非是非私を参考にしていただければと思います！
            </p>
            <p>
              働いているかどうか、子供がいるかどうか関係なくほとんどの方が基本的に忙しい日々を送っていると思います。
              時間がある時のためのスペシャルケアももちろん披露していきますが、毎日ケアは頑張りすぎなくて良いんです。
              自分にフィットするアイテム選びをしていただければ手間が減らせますからね。軽率にきれいになってしまいましょう！笑
            </p>
          </div>
          <section class="profile_media">
            <div class="profile_media_container">
              <div>
                <a href="https://www.instagram.com/manami1003/?hl=ja">
                  <img src="@/assets/logos/instagram_logo.svg">
                  manami1003
                </a>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section
        v-if="currentConciergeNumber===4"
        class="selected_concierge_eri"
      >
        <div class="selected_concierge_infoSnippet_container">
          <div class="selected_concierge_infoSnippet">
            <span>プロメイクアップアーティスト</span>
            <h2>Eri</h2>
            <img
              :src="
                ROOT_IMAGE_URL
                  + '/face-icon/eri.png'
              "
            >
            <p>
              カリフォルニア州で美容師免許を取得後、美容院に勤務。<br>
              日本に帰国後ヘアメイクに転身し、現在はサロン経営の傍ら広告やファッション業界でヘアメイクを担当
            </p>
          </div>
        </div>
        <div class="selected_concierge_info_container">
          <div class="selected_concierge_info">
            <h3>プロフィール</h3>
            <p>
              カリフォルニアで10代後半を過ごし、すっぴんでパジャマの状態でジャージしか着ていないライフスタイルだった、
              ずぼらな私がかれこれ10年以上美容業界で仕事をし、細々とサロンも営業させて頂いております。
            </p>
            <p>コスメ紹介を通じていかに楽に、簡単に、自分に満足できる顔、髪型を手に入れられるかをお伝えしていきたいと思っております。</p>
            <p>日本の女性は、みなさん器用でメイクも上手く、肌も綺麗と世界的に高い評価を受けていると思います。</p>
            <p>その事を誇りに、よりハッピーな、個々のスタイルを見つけて楽しい生活をおくれるお手伝い出来る事を楽しみにしてます。</p>
            <h4 class="profile_content_index">
              【肌質】
            </h4>
            <p>
              混合肌。<br>
              目元、口元は乾燥しやすく、鼻筋、おでこのテカりはすぐ出て来てしまう、厄介な肌質で、
              叔父、兄が重度のアトピーで、自分自身も免疫力が弱まるとアトピーぽい症状が出てしまいます。
            </p>
            <h4 class="profile_content_index">
              【ライフスタイル】
            </h4>
            <p>
              撮影の現場が不定期かつ、時間も不規則なので、肌荒れや体調は崩しやすく、
              お弁当や外食も多いので、休日は犬と一緒にのんびり過ごしたり家で自炊して食べる事が多いです。
            </p>
            <h4 class="profile_content_index">
              【どんな目線で製品紹介したいか】
            </h4>
            <p>
              広告（主にCM)やファッションのLook撮影やカタログの撮影で実際使ってモデルさんに喜ばれるコスメや、
              周りの美容業界の方にオススメされた製品など、コスパや成分だけではないトレンドや女子力を刺激するコスメのご紹介をしていきたいです。<br>
              流行っている物はもちろん、実際にプロの方が多く使っている物や長年人気なコスメの紹介も出来ればと思っております。
            </p>
          </div>
          <div class="profile_media">
            <div class="profile_media_container">
              <div>
                <a href="https://www.instagram.com/looper_hair_and_make_up/">
                  <img src="@/assets/logos/instagram_logo.svg">
                  looper_hair_and_make_up
                </a>
              </div>
              <div>
                <a href="https://www.erisato.tokyo/">
                  <img src="@/assets/logos/home.svg">
                  Studio&nbsp;Looper
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        v-if="currentConciergeNumber===5"
        class="selected_concierge_ayako"
      >
        <div class="selected_concierge_infoSnippet_container">
          <div class="selected_concierge_infoSnippet">
            <h2>Ayako</h2>
            <img
              :src="
                ROOT_IMAGE_URL
                  + '/face-icon/ayako.png'
              "
            >
            <p>
              スキンケア商品を主とするコスメブランドにて、営業と商品企画を経験。<br>
              その後、大手エステティック企業や香水メーカーでの事業運営を通じ、今までに100以上のアイテムを世に送り出してきた。<br>
              2019年4月に独立後、10年に渡る美容業界の経験を活かし、新規コスメブランドのプロデュースやサポートを行っている。
            </p>
          </div>
        </div>
        <div class="selected_concierge_info_container">
          <div class="selected_concierge_info">
            <h3>プロフィール</h3>
            <p>
              ポーラ・オルビスホールディングス傘下メーカーの化粧品の営業・企画開発職を経て、TBCグループ株式会社に入社。<br>
              リテール事業の立ち上げから美容・健康食品領域の企画開発、大手コンビニのPB製品などのコラボレーション事業に従事。<br>
              その後フレグランスメーカーを経て2019年より独立。<br>
              これまでスキンケア中心に100を超える製品企画に携わりオンライン・オフライン流通に展開。<br>
              現在はコスメ開発コンサルタントとして、美容関連商品の企画開発から事業立ち上げ、人材育成まで美容業界に関わる幅広い業務をサポートしています。
            </p>
            <br>
            <h3>コメント</h3>
            <p>ご訪問いただきありがとうございます。</p>
            <p>加齢、食事、睡眠、生理周期やストレス、環境変化など肌はさまざまな影響を受けて日々変化します。</p>
            <p>そのため、ある程度の効果を感じながらも心地よく、変化に左右されずに安心して使えることが化粧品の役割として大切なことだと思っています。</p>
            <p>これまで10年間、自身の肌だけではなく数百回行ってきた製品開発モニターでの知見などをもとにおすすめしたいアイテムをご紹介したいと思っています。</p>
            <br>
            <h3>商品紹介</h3>
            <div class="items">
              <img src="../../assets/curel.jpeg" width="100" height="100" style="border:none;" alt="">
              <a href="//af.moshimo.com/af/c/click?a_id=1115658&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fdp%2FB0096HZBGG" rel="nofollow" referrerpolicy="no-referrer-when-downgrade">Curel&nbsp;泡洗顔料</a>
              <img src="//i.moshimo.com/af/i/impression?a_id=1115658&p_id=170&pc_id=185&pl_id=4062" width="1" height="1" style="border:none;" alt="">
            </div>
            <br>
            <div class="items">
              <img src="../../assets/melvita.jpeg" width="100" height="100" style="border:none;" alt="">
              <a href="//af.moshimo.com/af/c/click?a_id=1115658&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fdp%2FB081DRYY6F" rel="nofollow" referrerpolicy="no-referrer-when-downgrade">Melvita&nbsp;フラワーブーケ&nbsp;ローズ&nbsp;EXトナー</a>
              <img src="//i.moshimo.com/af/i/impression?a_id=1115658&p_id=170&pc_id=185&pl_id=4062" width="1" height="1" style="border:none;" alt="">
            </div>
          </div>
        </div>
      </section>
      <section
        v-if="currentConciergeNumber===6"
        class="selected_concierge_nico"
      >
        <div class="selected_concierge_infoSnippet_container">
          <div class="selected_concierge_infoSnippet">
            <h2>Nico</h2>
            <img
              :src="
                ROOT_IMAGE_URL
                  + '/face-icon/nico.png'
              "
            >
            <p>
              外資系化粧品メーカーでの美容部員、メイクアップアーティスト、ブランドトレーナー、コスメ専門ECサイトバイヤー等
              25年以上に渡る化粧品業界での経験と、30歳すぎに経験した自身の肌荒れを皮切りに、不妊治療経験、更年期の劇的な
              カラダの変化など様々な実体験から「肌とカラダとココロを活かすナチュラルなトータルセルフケア」を実現する
              オーガニック・ナチュラルコスメをはじめ心地よく暮らすための衣食住の提案を得意とする。
            </p>
          </div>
        </div>
        <div class="selected_concierge_info_container">
          <div class="selected_concierge_info">
            <h3>プロフィール</h3>
            <p>セルフケアサロン「やさしい暮らしと肌の研究所」代表</p>
            <h3>コメント</h3>
            <p>こちらのページにご訪問いただきありがとうございます。</p>
            <p>長く化粧品業界を眺めてきた私が行き着いたのは、「スキンケアは人生をうるおわせる」ということ。</p>
            <p>そして、そのスキンケアになくてはならない「コスメ」は、人の生き方を左右するほど私たちにとって大切な人生のパートナーです。</p>
            <p>
              とかくカラダにダメージを与えるコトやモノが多くなった今の時代には、ほんの数分のスキンケアという行為が
              もっとも自分を近くに感じ癒すことのできる愛すべき時間だから、毎日たくさん肌と会話してほしいなと思います。
            </p>
            <p>肌はあなたのカラダとココロの小さな変化をつぶさに捉え、いつでもたくさんのことを教えてくれます。</p>
            <p>そんな肌に日々感謝しながら、カラダごと喜ぶあなただけの運命のコスメに出会ってくださいね。</p>
          </div>
          <section class="profile_media">
            <div class="profile_media_container">
              <div>
                <a href="https://r.goope.jp/nico-gusa">
                  <img src="@/assets/logos/home.svg">
                  隠れ家サロン「ヒーリングサロン和草」
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/nico_kazumi2593/">
                  <img src="@/assets/logos/instagram_logo.svg">
                  nico_kazumi2593
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/nicogusa.2593/?hl=ja">
                  <img src="@/assets/logos/instagram_logo.svg">
                  池尻三宿＊ヒーリングサロン和草
                </a>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section
        v-if="currentConciergeNumber===7"
        class="selected_concierge_peki"
      >
        <div class="selected_concierge_infoSnippet_container">
          <div class="selected_concierge_infoSnippet">
            <h2>ペキ</h2>
            <img
              :src="
                ROOT_IMAGE_URL
                  + '/face-icon/peki.png'
              "
            >
          </div>
        </div>
        <div class="selected_concierge_info_container">
          <div class="selected_concierge_info">
            <h3>プロフィール</h3>
            <p>
              21歳で美容師に就いてから、あっという間にアラフォーに近づいておりますが、ヘアメイク、エステを通じ美容がずっと身近にあります。<br>
              今はメイクの現場からは離れて社会人をやっておりますが、美容好きが高じて縁あって今回参加させて頂く運びとなりました。
            </p>
            <p>
              自分自身も炎症により化粧も出来ないひどい肌荒れに悩まされていた時期もありながら、令和をたくましく生きるお肌よわよわ人間です。<br>
              なので普段どんな化粧品でも試せる健康な肌の持ち主は大変幸せ者だと思います。<br>
              選び方が分からないなど迷った時には私の経験が道標になれたら幸いです。
            </p>
            <h4 class="profile_content_index">
              【呼ばれたい名前】
            </h4>
            <p>ペキ</p>
            <h4 class="profile_content_index">
              【肌質】
            </h4>
            <p>乾燥や炎症を起こしやすい敏感肌</p>
            <h4 class="profile_content_index">
              【ライフスタイル】
            </h4>
            <p>帰宅時間の遅いオフィス勤務の社会人</p>
            <h4 class="profile_content_index">【どんな目線で製品紹介したいか】</h4>
            <p>乾燥肌で悩んでて肌が弱くて普段思うように化粧が出来ない人に向けて私はこうしてますなど体験談を含めた紹介ができたらなと</p>
          </div>
        </div>
      </section>
    </section>
    <!-- メイク -->
    <section
      v-if="count===1"
      class="concierge_makeup_container"
    >
      <div class="concierge_inner">
        <div class="selected_concierge_eri">
          <div class="selected_concierge_infoSnippet_container">
            <div class="selected_concierge_infoSnippet">
              <span>プロメイクアップアーティスト</span>
              <h2>Eri</h2>
              <img
                :src="
                  ROOT_IMAGE_URL
                    + '/face-icon/eri.png'
                "
              >
              <p>
                カリフォルニア州で美容師免許を取得後、美容院に勤務。<br>
                日本に帰国後ヘアメイクに転身し、現在はサロン経営の傍ら広告やファッション業界でヘアメイクを担当
              </p>
            </div>
          </div>
          <div class="selected_concierge_info_container">
            <div class="selected_concierge_info">
              <h3>プロフィール</h3>
              <p>
                カリフォルニアで10代後半を過ごし、すっぴんでパジャマの状態でジャージしか着ていないライフスタイルだった、
                ずぼらな私がかれこれ10年以上美容業界で仕事をし、細々とサロンも営業させて頂いております。
              </p>
              <p>コスメ紹介を通じていかに楽に、簡単に、自分に満足できる顔、髪型を手に入れられるかをお伝えしていきたいと思っております。</p>
              <p>日本の女性は、みなさん器用でメイクも上手く、肌も綺麗と世界的に高い評価を受けていると思います。</p>
              <p>その事を誇りに、よりハッピーな、個々のスタイルを見つけて楽しい生活をおくれるお手伝い出来る事を楽しみにしてます。</p>
              <h4 class="profile_content_index">
                【肌質】
              </h4>
              <p>
                混合肌。<br>
                目元、口元は乾燥しやすく、鼻筋、おでこのテカりはすぐ出て来てしまう、厄介な肌質で、
                叔父、兄が重度のアトピーで、自分自身も免疫力が弱まるとアトピーぽい症状が出てしまいます。
              </p>
              <h4 class="profile_content_index">
                【ライフスタイル】
              </h4>
              <p>
                撮影の現場が不定期かつ、時間も不規則なので、肌荒れや体調は崩しやすく、
                お弁当や外食も多いので、休日は犬と一緒にのんびり過ごしたり家で自炊して食べる事が多いです。
              </p>
              <h4 class="profile_content_index">
                【どんな目線で製品紹介したいか】
              </h4>
              <p>
                広告（主にCM)やファッションのLook撮影やカタログの撮影で実際使ってモデルさんに喜ばれるコスメや、
                周りの美容業界の方にオススメされた製品など、コスパや成分だけではないトレンドや女子力を刺激するコスメのご紹介をしていきたいです。<br>
                流行っている物はもちろん、実際にプロの方が多く使っている物や長年人気なコスメの紹介も出来ればと思っております。
              </p>
            </div>
            <section class="profile_media">
              <div class="profile_media_container">
                <div>
                  <a href="https://www.instagram.com/looper_hair_and_make_up/">
                    <img src="@/assets/logos/instagram_logo.svg">
                    looper_hair_and_make_up
                  </a>
                </div>
                <div>
                  <a href="https://www.erisato.tokyo/">
                    <img src="@/assets/logos/home.svg">
                    Studio&nbsp;Looper
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <!-- 肌質 -->
    <section
      v-if="count===2"
      class="concierge_dermatologist_container"
    >
      <div class="concierge_inner">
        <div class="selected_concierge_hamano">
          <div class="selected_concierge_infoSnippet_container">
            <div class="selected_concierge_infoSnippet">
              <span>日本皮膚科学会認定&nbsp;皮膚科専門医</span>
              <h2>濱野英明</h2>
              <img
                :src="
                  ROOT_IMAGE_URL
                    + '/face-icon/hamano.png'
                "
              >
            </div>
          </div>
          <div class="selected_concierge_info_container">
            <div class="selected_concierge_info">
              <h3>プロフィール</h3>
              <h4 class="profile_content_index">
                略歴
              </h4>
              <p>
                聖マリアンナ医科大学卒業<br>
                川崎市立川崎病院(神奈川県)にて内科研修<br>
                慶應義塾大学病院皮膚科入局、勤務<br>
                稲城市立病院(東京都）皮膚科勤務<br>
                済生会横浜市南部病院(神奈川県）皮膚科勤務<br>
                平成22年11月&nbsp;テティス横濱美容皮膚科開設<br>
              </p>
              <h4 class="profile_content_index">
                所属学会
              </h4>
              <p>
                日本皮膚科学会（皮膚科専門医取得）<br>
                日本美容皮膚科学会<br>
                日本東洋医学会<br>
                日本レーザー医学会<br>
                日本スキンケア協会、皮膚科顧問<br>
                2015ミス・ユニバース&nbsp;ビューティーキャンプ講師<br>
                2018ミススプラインターナショナル神奈川、ビューティーキャンプ講師、審査員<br>
                ヒアルロン酸、ボトックス注射指導医
              </p>
            </div>
            <section class="profile_media">
              <div class="profile_media_container">
                <div>
                  <a href="https://www.thetis-y.com/">
                    <img src="@/assets/logos/home.svg">
                    テティス横濱美容皮膚科
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import concierges from '../../data/concierges'

export default {
  name: 'Concierges',
  props: {
    incomingConcierge: {
      type: String,
      default: "" // name_lowerを受け取る
    },
    incomingGenre: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      count: 0,
      concierges: concierges,
      currentConciergeNumber: 2,
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
    }
  },
  computed: {
    skincareConcierges(){
      return concierges.slice(1)
    },
  },
  mounted() {
    if(this.incomingGenre!=""){
      this.count = this.incomingGenre == "skincare" ? 0 : this.incomingGenre == "makeup" ? 1: 2
    }
    if(this.incomingConcierge!=""){
      this.currentConciergeNumber = concierges.find(
        concierge => concierge.name_lower==this.incomingConcierge).id
    }
  },
  methods: {
    // 大分類の選択
    setSkincare() {
      console.log(this.count)
      return this.count=0
    },
    setMakeup() {
      console.log(this.count)
      return this.count=1
    },
    setSkinTypeDiag() {
      console.log(this.count)
      return this.count=2
    },
    // コンシェルジュの選択
    selectConcierge(conciergeName) {
      this.currentConciergeNumber = concierges.find(
        concierge => concierge.name_lower==conciergeName).id
    },
  }
}
</script>
<style scoped>
main{
  font-family: "YuGothic";
}
h1{
  font-size: 24px;
  padding: 45px 0 45px 0;
  text-align: center;
}
h2{
  font-size: 26px;
}
h3{
  font-size: 22px;
  padding-bottom: 20px;
  font-weight: bold;
}
.concierge_genre{
  display: inline-block;
  text-align: center;
  font-size: 16px;
  line-height: 50px;
  width: 33.3%;
  border: 1px solid #F5EAE7;
}
.genre_selected{
  background-color: #F5EAE7;
}
.concierge_skincare_container{
  padding: 0 40px;
  background-color: #F5EAE7;
}
.concierge_select_container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
}
.concierge_select_btn{
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}
.concierge_select_btn_img{
  border-radius: 50%;
  border: solid 1px #CCCCCC;
  width: 60px;
  /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%); */
}
.selected_concierge_icon{
  width: 75px;
  border: solid 2px #F29F8F;
}
.selected_concierge_name{
  font-weight: bold;
}
.concierge_select_btn p{
  font-size: 14px;
  padding-top: 5px;
}
.selected_concierge_container{
  background-color: #F5EAE7;
}
.selected_concierge_infoSnippet{
  background-color: #F5EAE7;
}
.selected_concierge_infoSnippet_container{
  background-color: #F5EAE7;
  padding: 0 40px;
}
.selected_concierge_infoSnippet{
  padding: 30px 0;
}
.selected_concierge_infoSnippet span{
  font-size: 14px;
}
.selected_concierge_infoSnippet h2{
  font-size: 26px;
  font-weight: bold;
  padding: 5px 0;
}
.selected_concierge_infoSnippet img{
  margin: 15px 0;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}
.selected_concierge_info_container{
  padding: 0 40px;
  background-color: #FFFFFF;
}
.selected_concierge_info{
  padding: 50px 0;
}
.selected_concierge_info p{
  font-size: 14px;
  line-height: 1.4;
  padding-bottom: 20px;
}
.selected_concierge_info_bullets{
  padding-bottom: 20px;
}
.selected_concierge_info_bullets li{
  font-size: 14px;
  line-height: 1.4;
  list-style: square inside;
  text-indent: -1.4em;
  padding-left: 1em;
  padding-bottom: 7px;
}
.profile_content_index{
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 25px;
}
.profile_media_container{
  padding: 50px 0;
}
.profile_media_container a{
  display: inline-block;
  color: #F29F8F;
  padding-bottom: 30px;
}
.profile_media_container img{
  width: 16px;
}
.profile_media_container p{
  font-size: 14px;
  padding-bottom: 20px;
}

.items{
  display: flex;
  align-items: center;
}
</style>
